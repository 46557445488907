
import { useStore } from 'vuex';
import { Actions, ThirdPartyPayload } from '@/store/modules/ThirdPartyModule';
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import IconPallette from '@/components/icon/IconPallette.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { numberFormat } from '@/utils/helper/format';
import { hideModal } from '@/core/helpers/dom';
import {
  PromotionTypeEnum,
  AmountTypeEnum,
  PaymentTypes,
  ServicesTypes,
} from '../../core/data/constance';

export default {
  name: 'AddEditPromotion',
  components: {
    IconPallette,
    ConfirmModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref(null);
    const confirmModalRef = ref<any>(null);

    const state = reactive({
      loading: false,
      loaded: false,
      id: '' as string,
      couponTotal: 0,
      isAddCoupon: false,
      formData: {
        name: null,
        description: null,
        isActive: false,
        callback: null,
      } as ThirdPartyPayload,
    });

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: '',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    watch(
      () => route.params.id,
      async (id) => {
        if (id) {
          state.id = `${id}`;
          const thirdParty = await store.dispatch(`${Actions.FIND_BY_ID}`, id);

          state.formData = thirdParty;
        }
      }
    );

    onMounted(async () => {
      const { id } = route.params;

      if (id) {
        state.id = `${id}`;

        try {
          const thirdParty = await store.dispatch(`${Actions.FIND_BY_ID}`, id);

          state.formData = thirdParty;
        } catch (error: any) {
          console.error({ message: error.message });
        }
      }

      state.loaded = true;
    });

    const elRules = reactive({
      name: [
        {
          required: true,
          message: 'Please input title',
          trigger: 'change',
        },
        {
          pattern: /^[ก-ฮเ-ไA-Za-z0-9][ก-๙A-Za-z0-9.-\\ ]*$/,
          trigger: 'change',
          message: 'Invalid title',
        },
      ],
      callback: [
        {
          required: true,
          message: 'Please input title',
          trigger: 'change',
        },
        {
          pattern:
            /(http|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
          trigger: 'change',
          message: 'Invalid callback',
        },
      ],
    });

    return {
      formRef,
      confirmModalRef,
      ...toRefs(state),
      AmountTypeEnum,
      PromotionTypeEnum,
      PaymentTypes,
      ServicesTypes,
      numberFormat,
      async handleRevokeToken(e) {
        e.preventDefault();
        if (state.id) {
          state.loading = true;
          try {
            const thirdParty = await store.dispatch(
              Actions.REVOKE_TOKEN,
              state.id
            );

            state.formData = thirdParty;
            hideModal(confirmModalRef.value.$el);
            await Toast.fire({
              icon: 'success',
              title: 'Success',
            });

            state.loading = false;
          } catch (error) {
            console.error({ error });
            hideModal(confirmModalRef.value.$el);
            await Toast.fire({
              icon: 'error',
              title: 'Failed',
            });

            state.loading = false;
          }
        }
      },

      async handleChangeStatus(e) {
        e.preventDefault();
        if (state.id) {
          try {
            await store.dispatch(Actions.UPDATE_THIRD_PARTY_STATUS, {
              id: state.id,
              payload: state.formData,
            });

            await Toast.fire({
              icon: 'success',
              title: 'Success',
            });
          } catch (error) {
            console.error({ error });

            await Toast.fire({
              icon: 'error',
              title: 'Failed',
            });
          }
        }
      },
      elRules,
      async handleSubmit(formEl) {
        if (!formEl) return;

        formEl.validate(async (valid, fields) => {
          if (valid) {
            try {
              if (state.id) {
                await store.dispatch(`${Actions.UPDATE_THIRD_PARTY}`, {
                  id: state.id,
                  payload: state.formData,
                });
              } else {
                await store.dispatch(
                  `${Actions.CREATE_THIRD_PARTY}`,
                  state.formData
                );
              }

              Swal.fire({
                text: 'Form has been successfully submitted!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                router.push({ name: 'thirdPartyList' });
              });
            } catch (error) {
              console.error({ error });

              Swal.fire({
                text: 'Sorry, looks like there are some errors detected, please try again.',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            }
          } else {
            const errs: string[] = [];
            Object.values(fields).forEach(async ([errField]: any) => {
              errs.push(`${errField.field}: ${errField.message}`);
            });

            await Toast.fire({
              icon: 'error',
              title: `Invalid form data`,
            });
          }
        });
        return;
      },
    };
  },
};
